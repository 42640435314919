<template>
  <div class="display-area p-4">
    <div class="text-center" style="max-width: 600px; margin: auto">
      <slot name="header">
        <h2>WebAuthN Passwordless login</h2>
      </slot>
      <div>
        Insert your security key, and follow the prompts.
      </div>
      <br>
      <div class="d-none d-md-block pointer" style="height: 280px; width: 500px; border-radius: 5%; overflow: hidden; margin: auto" @click="fallbackClickToLogin">
        <img :src="yubiKeyGif" style="margin: -140px 0px 0px -150px">
      </div>

      <div v-if="displayButtons">
        <br>
        <div class="d-flex justify-content-around">
          <b-button v-if="enableRegistration" @click="register" variant="outline-primary" class="mr-3">Register</b-button>
          <b-button v-if="backButton" class="branded-primary-button" @click="$emit('back')">
            <slot name="backButton" />
          </b-button>
          <b-button @click="login" class="branded-primary-button">
            <slot name="callToAction">Login with device</slot>
          </b-button>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import yubiKeyGif from './yubikey.gif';
import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';
import base64url from '../../util/base64url';

export default {
  name: 'WebAuthNRedirectScreen',

  components: { BButton },

  props: {
    authenticationRequestId: {
      type: String,
      required: true
    },
    enableRegistration: {
      type: Boolean,
      required: false,
      default() { return false; }
    },
    authenticationProperties: {
      type: Object,
      required: false,
      default() { return {}; }
    },
    devices: {
      type: Array,
      require: false,
      default() { return []; }
    },
    backButton: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      yubiKeyGif,
      displayButtons: false,
      abortController: null
    };
  },

  computed: {
    host() {
      return this.$store.getters.host;
    }
  },

  async created() {
    if (!navigator.credentials?.get) {
      this.$emit('failed', { status: 404, message: 'Browser does not support WebAuthN login. Please try another login mechanism.' });
    }

    if (!navigator.credentials?.preventSilentAccess) {
      logger.track({ title: 'Browser does not support prevent silent access. Continuing anyway.' }, false);
    }

    if (!this.enableRegistration) {
      setTimeout(() => {
        this.openWebAuthnPrompt();
      }, 10);
      return;
    }

    if (await window?.PublicKeyCredential?.isConditionalMediationAvailable?.()) {
      logger.track({ title: 'Found Public Key credential functionality for conditionals, implement auto for existing credentials' }, false);
    }

    this.displayButtons = true;
  },

  methods: {
    async fallbackClickToLogin() {
      if (this.enableRegistration) {
        await this.login();
        return;
      }

      await this.openWebAuthnPrompt();
    },
    // Used by MFA Security Key flow during authentication, automatically opens to ask for credential signing of the authenticationRequestId
    async openWebAuthnPrompt() {
      const publicKeyCredentialRequestOptions = {
        challenge: Uint8Array.from(this.authenticationRequestId, c => c.charCodeAt(0)),
        rpId: window.location.host.split('.').slice(1).join('.'),
        // https://www.w3.org/TR/webauthn-3/#enumdef-userverificationrequirement
        userVerification: 'discouraged',
        allowCredentials: this.devices?.filter(d => d.type === 'WebAuthN').map(d => ({
          id: Uint8Array.from(atob(base64url.toBase64(d.webAuthnCredentialId)), c => c.charCodeAt(0)),
          type: 'public-key',
          // https://www.w3.org/TR/webauthn-3/#enum-transport
          transports: ['usb', 'ble', 'nfc', 'smart-card', 'hybrid', 'internal']
        })),
        timeout: 60000
      };

      if (this.abortController) {
        const abortError = new Error('UserRetriedPassKeyLoginSoForcingPreviousTryAbort');
        abortError.name = 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort';
        await this.abortController.abort(abortError);
      }
      this.abortController = new AbortController();

      try {
        const assertion = await navigator.credentials.get({
          // https://w3c.github.io/webappsec-credential-management/#enumdef-credentialmediationrequirement
          // mediation: 'optional',
          publicKey: publicKeyCredentialRequestOptions,
          signal: this.abortController.signal
        });
        const webAuthNTokenRequest = {
          authenticatorAttachment: assertion.authenticatorAttachment,
          credentialId: assertion.id,
          type: assertion.type,
          // overwrite the stored userId in the UI for use on new registration
          userId: String.fromCharCode(...new Uint8Array(assertion.response.userHandle)),
          signature: btoa(String.fromCharCode(...new Uint8Array(assertion.response.signature))),
          authenticator: btoa(String.fromCharCode(...new Uint8Array(assertion.response.authenticatorData))),
          client: btoa(String.fromCharCode(...new Uint8Array(assertion.response.clientDataJSON)))

        };
        const loginResponse = await new HttpClient().patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`,
          Object.assign({ code: webAuthNTokenRequest }, this.authenticationProperties));

        this.$emit('complete', loginResponse.data);
        return;
      } catch (error) {
        if (error.name === 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort') {
          logger.log({ title: 'User is forcing retry of webauthn', error, authenticationRequestId: this.authenticationRequestId });
          this.displayButtons = true;
          return;
        }

        if (error.name === 'NotAllowedError' || error.name === 'AbortError' || error.message === 'The operation either timed out or was not allowed.') {
          logger.warn({ title: 'WebAuthN login failed due to abort or not allowed', error, authenticationRequestId: this.authenticationRequestId });
          this.displayButtons = true;
          return;
        }
        logger.warn({ title: 'WebAuthN login failed due to unknown error', error, authenticationRequestId: this.authenticationRequestId });
        this.$emit('failed', error);
        this.displayButtons = true;
        return;
      }
    },

    async register() {
      // For resident keys, this can be the actual userId, otherwise it doesn't matter.
      // > The user handle is required for usernameless passwordless authentication with discoverable credentials, i.e., credentials that live on the security key.
      // https://developers.yubico.com/WebAuthn/WebAuthn_Developer_Guide/User_Handle.html
      const generatedUserId = this.$store.state.cache?.webAuthN?.userId || 'WebAuthN';

      // https://developer.mozilla.org/en-US/docs/Web/API/CredentialsContainer/create
      const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from(this.authenticationRequestId, c => c.charCodeAt(0)),
        rp: {
          name: 'Passkey Login',
          id: window.location.host.split('.').slice(1).join('.')
        },
        user: {
          id: Uint8Array.from(generatedUserId, c => c.charCodeAt(0)),
          name: generatedUserId,
          displayName: 'Passkey Login'
        },
        // https://www.iana.org/assignments/cose/cose.xhtml#algorithms (Order Matters)
        pubKeyCredParams: [
          // These other ones are disabled in the library
          // { type: 'public-key', alg: -8 }, /* EdDSA */
          // { type: 'public-key', alg: -36 }, /* ES512 */
          // { type: 'public-key', alg: -35 }, /* ES384 */
          { type: 'public-key', alg: -7 }, /* ES256 */
          // { type: 'public-key', alg: -39 }, /* PS512 */
          // { type: 'public-key', alg: -38 }, /* PS384 */
          // { type: 'public-key', alg: -37 }, /* PS256 */
          // { type: 'public-key', alg: -259 }, /* RS512 */
          // { type: 'public-key', alg: -258 }, /* RS384 */
          { type: 'public-key', alg: -257 } /* RS256 */
        ],
        authenticatorSelection: {
          // We require the resident key because this is a first factor auth method. When used as 2FA, then it doesn't need to be resident, because we can send back all the credentialIds associated with the account
          residentKey: 'required',
          // https://www.w3.org/TR/webauthn-3/#enumdef-userverificationrequirement
          userVerification: 'discouraged'
        },
        timeout: 60000,
        attestation: 'direct'
      };

      if (this.abortController) {
        const abortError = new Error('UserRetriedPassKeyLoginSoForcingPreviousTryAbort');
        abortError.name = 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort';
        await this.abortController.abort(abortError);
      }
      this.abortController = new AbortController();

      try {
        const credential = await navigator.credentials.create({
          publicKey: publicKeyCredentialCreationOptions,
          signal: this.abortController.signal
        });

        const webAuthNTokenRequest = {
          authenticatorAttachment: credential.authenticatorAttachment,
          credentialId: credential.id,
          type: credential.type,
          userId: generatedUserId,
          attestation: btoa(String.fromCharCode(...new Uint8Array(credential.response.attestationObject))),
          client: btoa(String.fromCharCode(...new Uint8Array(credential.response.clientDataJSON)))
        };

        const loginResponse = await new HttpClient().patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`, { code: webAuthNTokenRequest });
        this.$emit('complete', loginResponse.data);
      } catch (error) {
        if (error.name === 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort') {
          logger.log({ title: 'User is forcing retry of webauthn', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }

        if (error.name === 'NotAllowedError' || error.name === 'AbortError' || error.message === 'The operation either timed out or was not allowed.') {
          logger.warn({ title: 'WebAuthN registration failed because it was cancelled by the user', error, authenticationRequestId: this.authenticationRequestId });
          this.$emit('failed', { status: 404, message: 'Login cancelled by user.', data: error });
          return;
        }
        logger.warn({ title: 'WebAuthN login failed due to unknown error', error, authenticationRequestId: this.authenticationRequestId });
        this.$emit('failed', error);
      }
    },

    async login() {
      const publicKeyCredentialRequestOptions = {
        challenge: Uint8Array.from(this.authenticationRequestId, c => c.charCodeAt(0)),
        rpId: window.location.host.split('.').slice(1).join('.'),
        // https://www.w3.org/TR/webauthn-3/#enumdef-userverificationrequirement
        // * We have to require user verification because if someone finds this device we do not want them free reign to just log in with it, they must prove they are the rightful owner.
        userVerification: 'preferred',
        // allowCredentials: [{
        //   id: Uint8Array.from('forcedCredentialsId', c => c.charCodeAt(0)),
        //   type: 'public-key',
        //   transports: ['usb', 'ble', 'nfc']
        // }],
        timeout: 60000
      };

      if (this.abortController) {
        const abortError = new Error('UserRetriedPassKeyLoginSoForcingPreviousTryAbort');
        abortError.name = 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort';
        await this.abortController.abort(abortError);
      }
      this.abortController = new AbortController();

      try {
        // We want to know if there are credentials and sign the user in, if there aren't credentials, then we need to ask them whether or not they want to register or sign in (because we don't know)
        // * So we pick conditional when possible or silent otherwise, and then fallback to the user what they want to do.
        // If the user's browser supports conditional, then fetch the list of resident credentials from the device and use that to log in.
        // * The user's browser doesn't support conditional, then attempt to directly log them in. And if that doesn't work then go to registration
        // * Silent - we have no control but will attempt to get credentials
        // * Conditional - show the UI to let the user select the credentials or push their security key, and use the result to authenticate
        //                 If the user doesn't have any credentials then assertion below will be null, and we'll assume they need to register
        // https://github.com/w3c/webauthn/wiki/Explainer:-WebAuthn-Conditional-UI
        // const isConditionalMediationAvailable = await window?.PublicKeyCredential?.isConditionalMediationAvailable?.();

        // Currently is set to false because `get` is only fulfilled or rejected if an action is taken, otherwise this method will hang and we definitely don't want that.
        const isConditionalMediationAvailable = false;
        const assertion = await navigator.credentials.get({
          mediation: isConditionalMediationAvailable ? 'conditional' : 'silent',
          publicKey: publicKeyCredentialRequestOptions,
          signal: this.abortController.signal
        });
        if (assertion) {
          const webAuthNTokenRequest = {
            authenticatorAttachment: assertion.authenticatorAttachment,
            credentialId: assertion.id,
            type: assertion.type,
            userId: String.fromCharCode(...new Uint8Array(assertion.response.userHandle)),
            signature: btoa(String.fromCharCode(...new Uint8Array(assertion.response.signature))),
            authenticator: btoa(String.fromCharCode(...new Uint8Array(assertion.response.authenticatorData))),
            client: btoa(String.fromCharCode(...new Uint8Array(assertion.response.clientDataJSON)))
          };

          const loginResponse = await new HttpClient().patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`, { code: webAuthNTokenRequest });
          this.$emit('complete', loginResponse.data);
          return;
        }
      } catch (error) {
        if (error.name === 'UserRetriedPassKeyLoginSoForcingPreviousTryAbort') {
          logger.log({ title: 'User is forcing retry of webauthn', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }

        if (error.name === 'NotAllowedError' || error.name === 'AbortError' || error.message === 'The operation either timed out or was not allowed.') {
          logger.warn({ title: 'WebAuthN login failed due to abort or not allowed', error, authenticationRequestId: this.authenticationRequestId });
          return;
        }
        logger.warn({ title: 'WebAuthN login failed', error, authenticationRequestId: this.authenticationRequestId });
        this.$emit('failed', error);
        return;
      }

      if (this.enableRegistration) {
        await this.register();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  pre {
    overflow-wrap: break-word;
  }
</style>
