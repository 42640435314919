<template>
  <b-button @click="onClick" :tabindex="tabindex" :disabled="innerDisabled" :variant="variant">
    <div ref="button" v-if="!inProgress">
      <slot v-if="!completed" />
      <div v-else :style="{ 'width': computedWidth, 'height': computedHeight }" class="text-secondary d-flex align-items-center justify-content-center">
        <fa icon="check" />
      </div>
    </div>
    <div v-else :style="{ 'width': computedWidth, 'height': computedHeight }" class="d-flex align-items-center justify-content-center">
      <fa icon="spinner" spin />
    </div>
  </b-button>
</template>

<script>
import logger from '../clients/logger';
import { BButton } from 'bootstrap-vue';
export default {
  name: 'ActivityButton',

  components: { BButton },

  props: {
    action: {
      type: Function,
      required: false,
      default() {}
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    event: {
      type: String,
      required: false,
      default: null
    },
    variant: {
      type: String,
      required: false,
      default: 'outline-primary'
    },
    tabindex: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      inProgress: false,
      completed: false,
      width: null,
      height: null
    };
  },

  computed: {
    innerDisabled() {
      return this.inProgress || this.disabled;
    },
    computedHeight() {
      return this.height && `${this.height}px`;
    },
    computedWidth() {
      return this.width && `${this.width}px`;
    }
  },

  methods: {
    async onClick() {
      this.width = this.$refs.button.clientWidth;
      this.height = this.$refs.button.clientHeight;
      const inProgressAsync = new Promise(resolve => setTimeout(resolve, 10)).then(() => 'RACE');
      this.sendEvent();

      let actionCompletedSuccessFully = false;
      if (this.action) {
        try {
          const actionAsync = this.action();
          const raceResult = await Promise.race([inProgressAsync, actionAsync]);
          if (raceResult === 'RACE') {
            this.inProgress = true;
          }
          actionCompletedSuccessFully = await actionAsync;
        } catch (error) {
          logger.error({ title: 'Button action error', error, buttonName: this.action.name?.replace(/^bound\s+/i, ''), props: this.$data });
        }
      }
      this.inProgress = false;
      if (actionCompletedSuccessFully) {
        this.completed = true;
        setTimeout(() => { this.completed = false; }, 1000);
      }
    },

    sendEvent() {
      const category = this.$route.name;
      let action;
      if (this.event) {
        action = this.event;
      } else if (this.action && this.action.name && this.action.name !== 'anonymous' && this.action.name !== 'action') {
        action = this.action.name.replace('bound ', '');
      } else {
        return;
      }
      this.fullStory.event(action, { event_category_str: category });
    }
  }
};
</script>
