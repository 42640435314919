<template>
  <div class="display-area p-4 d-flex flex-column">
    <b-link v-if="backButton" @click="$emit('back')" class="branded-primary-link"><smalL><fa icon="left-long" /> Use hardware device instead</small></b-link>
    <div class="d-flex align-items-center flex-grow-1">
      <div class="text-center" style="max-width: 600px; margin: auto">
        <slot name="header">
          <h2>Enter Mobile Authenticator Code</h2>
        </slot>
        <div>
          Generate a code from your Mobile Authenticator and enter it here:
        </div>
        <br>
        
        <form class="d-flex flex-column h-100">
          <div class="mx-md-3">
            <fieldset>
              <div>
                <input style="font-size: 32px; text-align: center;" class="form-control" autocomplete="one-time-code"
                  type="text" pattern="\d{0,6}" inputmode="numeric"
                  placeholder="0 0 0 0 0 0" maxlength="6"
                  v-model="totpCode"
                  @input="v => handleTotpCode(v)">
              </div>
            </fieldset>
          </div>
          <div class="mt-4" style="width: 100%; height: 50px; display: flex; justify-content: center">
            <span v-if="loading"><loader /></span>
            <span v-else-if="error" class="text-danger">{{ error }}</span>
            <span v-else>&nbsp;</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';

export default {
  name: 'TotpChallengeRedirectScreen',

  components: { },

  props: {
    authenticationRequestId: {
      type: String,
      required: true
    },
    backButton: {
      type: Boolean,
      require: false,
      default: false
    },
    authenticationProperties: {
      type: Object,
      required: false,
      default() { return {}; }
    }
  },
  data() {
    return {
      loading: false,
      totpCode: '',
      error: null,
      errorCount: 0
    };
  },

  computed: {
    host() {
      return this.$store.getters.host;
    }
  },

  async created() {},

  methods: {
    async handleTotpCode() {
      this.error = null;
      if (this.totpCode.match(/[^\d]/g)) {
        this.totpCode = this.totpCode.replace(/[^\d]+/gi, '');
        return;
      }
      if (this.totpCode.length !== 6) {
        return;
      }
      this.loading = true;
      const awaiter = new Promise(resolve => setTimeout(resolve, 300));
      try {
        const loginResponse = await new HttpClient().patch(`/authentication/${encodeURIComponent(this.authenticationRequestId)}`,
          Object.assign({ code: this.totpCode }, this.authenticationProperties));
        await awaiter;
        this.$emit('complete', loginResponse.data);
        return;
      } catch (error) {
        await awaiter;
        this.loading = false;
        logger.warn({ title: 'TOTP login failed', error, authenticationRequestId: this.authenticationRequestId });
        this.error = 'The entered code is invalid or expired, please try again.';

        this.errorCount++;
        if (this.errorCode > 5) {
          this.$emit('failed', error);
        }
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  pre {
    overflow-wrap: break-word;
  }
  .display-area {
    height: 400px;
  }
</style>
