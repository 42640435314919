<template>
  <div>
    <loader v-if="loading" pageLoader />
  </div>
</template>

<script>
import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';

export default {
  name: 'LogoutScreen',

  data() {
    return {
      loading: true
    };
  },

  computed: {
    applicationId() {
      return this.$route.query.applicationId || this.$route.query.client_id;
    },
    applicationSourceLocation() {
      const referrer = (document.referrer || document.referer) ? new URL(document.referrer || document.referer) : undefined;
      const likelySourceLocation = referrer?.toString() || '';
      if (!likelySourceLocation) {
        return null;
      }

      // The source location is for sure not this UI, so return null if it looks like it will match
      if (new URL(likelySourceLocation).hostname === window.location.hostname) {
        return null;
      }

      return likelySourceLocation;
    },
    redirectUrl() {
      const redirectUrl = this.$route.query.redirect_uri;
      if (!redirectUrl) {
        return this.applicationSourceLocation || '';
      }

      // Check if redirect url is absolute
      try {
        return new URL(redirectUrl).toString();
      } catch (error) {
        logger.log({ title: 'Invalid redirect on logout, attempting next check', applicationId: this.applicationId, redirectUrl, applicationSourceLocation: this.applicationSourceLocation });
      }

      // Next check if the redirect url is relative
      try {
        return new URL(redirectUrl, this.applicationSourceLocation).toString();
      } catch (error) {
        logger.log({ title: 'Redirect is not a partial path, ignoring', applicationId: this.applicationId, redirectUrl, applicationSourceLocation: this.applicationSourceLocation });
      }

      // If it is still garbage, don't even both sending it to the API, because we'll get back garbage so instead send the originating location.
      return this.applicationSourceLocation || '';
    }
  },

  /**
   * We get here in four ways:
     * Localhost
     * Cross Domain
     * DELETE /session failed from loginClient.logout()
     * The user isn't logged in at all, so the this.enableCredentials = false in the SDK.
    NOTE: It is important to consider all the flows that clients will call this, either from the loginClient.logout() AND also the LoginUI /logout route.
   */
  async created() {
    try {
      const logoutResponse = await new HttpClient().delete(`/session?client_id=${encodeURIComponent(this.applicationId)}&redirect_uri=${encodeURIComponent(this.redirectUrl)}`, {}, true);
      // eslint-disable-next-line no-console
      console.debug({ title: 'Logout Success', cookies: document.cookie, logoutResponse: logoutResponse.data });
      if (logoutResponse.data.redirectUrl) {
        window.location.replace(logoutResponse.data.redirectUrl);
        return;
      }
    } catch (error) {
      logger.warn({ title: 'logout unsuccessful, using fallback locations to determine where to send the user.', error,
        requestedRedirectUrl: this.redirectUrl, applicationSourceLocation: this.applicationSourceLocation });
    }

    // This means the user wasn't logged in. So let's see if we are okay sending the user back where they came from (does the origin match)
    // The truth is we can't avoid the previous call because we don't have access to the "authress-session" cookie in the browser, so we always need to make that request.
    // * If we end up here it means the session wasn't valid, so we don't want to waste resources on a potential attack going to the DB. Instead handle everything in here.

    if (this.redirectUrl && this.applicationSourceLocation && new URL(this.redirectUrl).origin === new URL(this.applicationSourceLocation).origin) {
      // The source location is the same as the redirect url, just let the user return to their desired location.
      window.location.replace(this.redirectUrl);
      return;
    }

    // This means the user wasn't logged in AND the redirect url does not match the source location. Either the redirect url is an attack OR it is cross domain.
    // * In the cross domain scenario, send the user back to where they came from, it's not the best, but honestly, don't call logout if the user isn't logged in

    // This means the redirect url is invalid and the user is not logged in, so we have no choice but to return them back to where they came from.
    if (this.applicationSourceLocation) {
      logger.track({ title: 'Returning the user in a potential cross domain scenario back to where they came from instead of the redirect url. If this happens frequently, we should try to figure out if it is worth returning a logoutResponse redirect url instead of what we are doing here. Normally the goal is to prevent unnecessary DB access in the service for users that are not logged in. But this user not logged in, in a cross domain scenario is not being sent back to the right location because we cannot look it up AND we do not trust that cross domain. Which is a bad user experience', applicationSourceLocation: this.applicationSourceLocation });
      window.location.replace(this.applicationSourceLocation);
      return;
    }

    // Well shoot, at this point the redirect url is invalid and we don't know where the user came from. So we'll just return them to the top level domain
    const appLocationFallbackUrl = window.location.origin.replace(window.location.hostname, window.location.hostname.replace(/^[^.]+[.]/, ''));

    logger.track({ title: 'We have no idea where the user came from nor who the user is, because they are not logged in, but need to be returned somewhere now, and everywhere after log out is not trusted. So we are sending them back to the match top level eTLD+1. What is going on here? Is there something weird in the browser url we can make use of?', redirectUrl: this.redirectUrl, hostname: window.location.hostname, appLocationFallbackUrl, referrer: document.referrer || document.referer });

    window.location.replace(appLocationFallbackUrl);
  }
};
</script>

<style scoped>

</style>
