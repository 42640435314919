import buildInfo from './buildInfo';

function getEnvironment() {
  if (!window || !window.location) {
    return null;
  }

  if (window.location.hostname === 'localhost') {
    return 'development';
  }
  if (window.location.hostname === `tst.${buildInfo.deployment.fdqn}` && window.location.pathname.includes('/PR')) {
    return 'pull-request';
  }
  if (window.location.hostname === `tst.${buildInfo.deployment.fdqn}`) {
    return 'test';
  }
  return 'production';
}

export default getEnvironment();
