import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=1b4b4c62&scoped=true&"
import script from "./settings.vue?vue&type=script&lang=js&"
export * from "./settings.vue?vue&type=script&lang=js&"
import style0 from "./settings.vue?vue&type=style&index=0&id=1b4b4c62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4b4c62",
  null
  
)

export default component.exports