<template>
  <div>
    <div v-if="goToEmailToVerify">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <div>
                  An email was sent to your email inbox to complete login.
              </div>
              <br>
              <div>
                <email-linker :email="email" :from-email-domain="host" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="success">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>The email address for your account has now been confirmed, you may now close this tab.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>Verifying your email address.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <loader />
      </div>
    </div>

    <div v-else-if="showNewPasswordDisplay">
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <h3 v-if="domainConfiguration.logo" class="d-flex justify-content-center w-100 mb-2">
          <img style="height: 36px; border-radius: 10px" :src="domainConfiguration.logo">
        </h3>
        <h3 class="d-flex justify-content-center w-100 mb-2"><span>{{ signup ? 'Sign Up' : domainConfiguration.title || 'Sign In' }}</span></h3>
        <small>Return to <strong class="support-link pointer" @click="$emit('input', false)">Sign in</strong></small>
      </div>

      <div class="d-flex justify-content-center">
        <BFormGroup style="width: 300px; max-width: 95%; margin-bottom: 0;">
          <template #label><span style="font-size: 12px; padding-bottom: 0;">EMAIL</span></template>
          <BFormInput :disabled="true" class="flex-shrink-0" maxlength="100" style="width: 300px; max-width: 100%" required
            autofocus autocomplete="on" name="email" id="email" :value="email" />
        </BFormGroup>
      </div>

      <template v-if="!emailSent">
        <span class="text-primary">Enter a new password</span>
        <BFormGroup :state="hasErrors" style="width: 300px; max-width: 95%; margin-bottom: 0;">
          <b-form-input class="password-input flex-shrink-0 fs-exclude" maxlength="100" style="width: 300px; max-width: 100%" required
            placeholder="•••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••"
            autocomplete="new-password" type="password" name="password" id="password" :state="validations.passwordInput && validations.passwordIsLongEnough"
            v-model="passwordInput"
            @keyup.enter="() => updatePasswordAndReturnToApplication()" />
        </BFormGroup>

        <div class="d-flex justify-content-center mt-2">
          <b-form-group style="width: 300px; max-width: 95%">
            <activity-button class="btn-sm branded-primary-button pointer" style="width: 100% !important;"
            :action="() => updatePasswordAndReturnToApplication()">
              <span>Update and return to application</span>
            </activity-button>
          </b-form-group>
        </div>
      </template>
    </div>

    <div v-else-if="retryEmail">
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>The current verification is no longer valid. Please check your email for a new link.</span>
              </div>
              <br>
              <div>
                <email-linker :email="retryEmail" :from-email-domain="host" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="d-panel-body d-flex justify-content-center">
        <div class="group" style="padding: 0">
          <div class="justify-content-center d-flex w-100" style="min-width: 150px">
            <div class="d-flex flex-wrap justify-content-center">
              <div>
                <span>We cannot verify your email address at the current time, please try again later.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from '../../clients/httpClient';
import logger from '../../clients/logger';
import jwtManager from '../../util/jwtManager.js';

export default {
  name: 'ManagedVerifyEmailFlow',

  components: {},

  props: {
    email: {
      type: String,
      required: false,
      default() { return ''; }
    },
    goToEmailToVerify: {
      type: Boolean,
      required: false,
      default() { return false; }
    }
  },
  data() {
    return {
      loading: true,
      showNewPasswordDisplay: false,
      showValidations: false,
      success: false,

      passwordInput: null,
      retryEmail: null
    };
  },

  computed: {
    host() {
      return this.$store.getters.host;
    },
    code() {
      return this.$route.query.code;
    },
    nextAction() {
      return this.$route.query.nextAction;
    },
    authenticationRequestId() {
      return this.$route.query.requestId;
    },

    validations() {
      if (!this.showValidations) {
        return {};
      }

      return {
        passwordInput: !!this.passwordInput?.match(/^.{1,}$/),
        passwordIsLongEnough: !!this.passwordInput?.match(/^.{20,72}$/)
      };
    },
    hasErrors() {
      return Object.values(this.validations).some(v => v === false);
    }
  },

  async created() {
    if (!this.goToEmailToVerify) {
      await this.verifyEmail();
    }
  },

  methods: {
    async verifyEmail() {
      const awaiter = new Promise(resolve => setTimeout(resolve, 1000));
      logger.log({ title: 'User is attempting to verify email', code: this.code, authenticationRequestId: this.authenticationRequestId });
      try {
        const verificationResult = await new HttpClient().patch(`/authentication/${this.authenticationRequestId}`, {
          loginType: 'VerifyEmail',
          code: this.code,
          connectionId: 'password'
        });
        this.$store.commit('setLastSelectedConnection', null);

        if (this.nextAction === 'password') {
          this.showNewPasswordDisplay = true;
          this.verificationResult = verificationResult;
          return;
        }

        if (this.nextAction) {
          logger.track({ title: 'NextAction is set when verifying email but we are defaulting to the redirect. Implement this next action', nextAction: this.nextAction });
        }

        // else just redirect
        await awaiter;
        if (verificationResult.data.redirectUrl) {
          window.location.replace(verificationResult.data.redirectUrl);
          return;
        }
        this.success = true;
        logger.track({ title: 'Email address verification did not return a redirectUrl, this happens when the user is really an Authress user and they are testing the email login flow. We should actually be sending the user to the /login response and not a /?flow=verify, because the EmailVerify flow is actually a /login code response flow, and that screen already knows what to do with a "none" response type/location.', verificationResult });
        return;
      } catch (error) {
        if (error.status === 409 && error.data?.nextAction === 'retry') {
          logger.warn({ title: 'Failed to verify email because something expired on the API side, retrying.', error, code: this.code });
          this.retryEmail = error.data.email;
          return;
        }
        logger.error({ title: 'Failed to verify email, we should automatically send out another email when this happens which includes a next Action.', error, code: this.code });
        await awaiter;
        this.loading = false;
        return;
      }
    },

    async updatePasswordAndReturnToApplication() {
      this.showValidations = true;

      if (this.hasErrors) {
        return;
      }

      const awaiter = new Promise(resolve => setTimeout(resolve, 500));
      const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ password: this.passwordInput, code: this.code }, { requiresExtendedMatch: true });
      try {
        await new HttpClient().patch('/session/flows', { flowType: 'setPassword', antiAbuseHash, password: this.passwordInput, code: this.code });
        logger.info({ title: 'User is setting their password' });
      } catch (error) {
        logger.error({ title: 'Failed to complete the password update', error });
        // If it is a known error we would be able to do something about it, but because it is likely unknown, we instead will just tell the user that it worked and move on.
      }

      await awaiter;
      window.location.replace(this.verificationResult.data.redirectUrl);
    }
  }
};
</script>

<style scoped></style>
