import './styles/style.scss';

if (!window.location.origin) {
  // eslint-disable-next-line prefer-template
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

// Here we are swapping out the temporary DOM which hosts the vanilia javascript with the the DOM which VueJS will edit.
// Since we don't have Vue loaded yet, the only solution is to directly edit the temporary DOM and remove the elements once VueJS is loaded.
// If we swap out the whole element we will see a screen flicker, to avoid this certain elements are kept until after VueJS is loaded and then removed here such as the loader and navigation.
// Once VueJS is loaded AND these elements are no longer visable on the screen the main.js property "initialLoad" is set to true.

function loadApp() {
  const loadingNote1 = setTimeout(() => document.getElementById('loading-note').innerText = 'Taking longer than expected... Please stand by', 7500);
  const loadingNote2 = setTimeout(() => {
    document.getElementById('loading-note').innerHTML
      = 'If you still see this message, please try reloading the page or contact us at <a href="mailto:support@authress.io">support@authress.io</a>';
  }, 60000);
  try {
    require('./index');
    const loader = document.getElementById('loading-nav');
    if (loader) { loader.outerHTML = ''; }
    const loaderError = document.getElementById('loading-error');
    if (loaderError) { loaderError.outerHTML = ''; }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('App did not load', error);
    const loader = document.getElementById('initial-loader');
    if (loader) { loader.outerHTML = ''; }
    const loaderError = document.getElementById('loading-error');
    if (loaderError) { loaderError.style.display = ''; }
  }

  clearTimeout(loadingNote1);
  clearTimeout(loadingNote2);
}

loadApp();
