<template>
  <div v-if="error" class="col-12 col-lg-8 display-area p-5">
    <div v-if="error === 'MISSING_APPLICATION_ID'">
      <h2>Login incomplete</h2>
      <div>
        The OAuth <code>client_id</code> parameter is missing in the url.
        <br><br>
        To complete the login flow, add the missing the parameter and retry the login request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_APPLICATION'">
      <h2>Login incomplete</h2>
      <div>
        The specified application <code>{{ applicationId }}</code> is not enabled for login.
        <br><br>
        To complete the login flow, review the <code>client_id</code> associated with this request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'UNEXPECTED_PARAMETER_IN_URL'">
      <h2>Login incomplete</h2>
      <div>
        An unexpected parameter was found when attempting to authenticate. <code>{{ unexpectedParameterInUrl }}</code> is not a valid parameter.
        <br><br>
        To complete the login flow, review the parameters included in this request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_INVITE'">
      <h2>Login incomplete</h2>
      <div>
        The specified request_uri <code>{{ $route.query.request_uri }}</code> is invalid or not found.
        <br><br>
        To complete the login flow, review the <code>request_uri</code> associated with this request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_RESOURCE'">
      <h2>Login incomplete</h2>
      <div>
        The specified resource <code>{{ resourceAudienceIdUri }}</code> is invalid or not found.
        <br><br>
        To complete the login flow, review the <code>resource</code> associated with this request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'INVALID_REDIRECT'">
      <h2>Login incomplete</h2>
      <div>
        The specified application <code>{{ applicationId }}</code> has not enabled the <code>redirect_uri</code>.
        <br><br>
        To complete the login flow, review the <code>redirect_uri</code> associated with this request: <code>{{ redirectUrl }}</code>.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else-if="error === 'MISSING_CODE_CHALLENGE'">
      <h2>Login incomplete</h2>
      <div>
        The OAuth <code>code_challenge</code> parameter is missing in the url.
        <br><br>
        To complete the login flow, add the missing the parameter and retry the login request.
        <br><br>
        <div class="d-flex">
          <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
        </div>
        <br>
        <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
      </div>
    </div>

    <div v-else>
      An unknown error occurred while attempting to log in.
      <br><br>
      The login service generated the following error:<br>
      <pre><code>{{ error }}</code></pre>
      Please try again by navigating back to the app.
      <br><br>
      <div class="d-flex">
        <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
      </div>
      <br><br>
      <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
    </div>
  </div>

  <div v-else-if="isFederatedLoginUi">
    <federation />
  </div>

  <div v-else-if="loading" style="height: 100%">
    <loader pageLoader />
  </div>

  <div v-else>
    <transition name="slide-fade" appear>
      <login-form :domainConfiguration="domainConfiguration" />
    </transition>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';
import LoginForm from './loginForm/loginForm.vue';
import jwtManager from '../util/jwtManager.js';

import Federation from './federation';

export default {
  name: 'HomeScreen',

  components: {
    BButton, Federation, LoginForm
  },

  props: {
    authenticationRequestId: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      host: this.$store.getters.host,
      loading: true,

      domainConfiguration: {},
      error: null
    };
  },

  computed: {
    connectionId() {
      return this.$route.query.connectionId;
    },
    applicationId() {
      return this.$route.query.applicationId || this.$route.query.client_id;
    },
    inviteId() {
      if (this.$route.query.request_uri?.startsWith('urn:authress:authentication:params:invite:')) {
        return this.$route.query.request_uri.split(':').slice(-1)[0];
      }
      return null;
    },
    resourceAudienceIdUri() {
      return this.$route.query.resource;
    },
    unexpectedParameterInUrl() {
      const allowedParameterMap = {
        client_id: true,
        redirect_uri: true,
        resource: true,
        connectionId: true,
        applicationId: true,
        federation: true,
        code_challenge: true,
        code_challenge_method: true,
        state: true,
        scope: true,
        // response_type: true,
        response_mode: true,
        request_uri: true,
        // nonce: true,
        login_hint: true,
        prompt: true
      };

      const foundKey = Object.keys(this.$route.query).find(key => !allowedParameterMap[key]);
      return foundKey;
    },
    redirectUrl() {
      const referrer = (document.referrer || document.referer) ? new URL(document.referrer || document.referer) : undefined;
      return this.$route.query.redirect_uri || referrer?.toString();
    },
    isFederatedLoginUi() {
      return window.location.hostname === 'login.authress.com' || window.location.hostname === 'localhost' && new URLSearchParams(window.location.search).get('federation');
    }
  },
  async created() {
    // In some cases some providers don't have the ability to redirect the user to the correct /path, so instead we have to guess that they wanted these based on the url parameters
    if (this.isFederatedLoginUi) {
      return;
    }
    // Handles Extension log in. Extension redirect here by default because they are using the OAuth flow, which says directly navigate to the /authorize url (which in this case is this top level route)
    // * Then we have to handle the authentication directly
    if (this.applicationId || this.$route.query.code_challenge) {
      await this.extensionAndLegacyNonRequestOauthFlow();
      return;
    }

    if (this.authenticationRequestId) {
      logger.error({ title: 'AuthenticationRequestId was specified as a path parameter which is no longer supported, it should always be  passed in the query string',
        authenticationRequestId: this.authenticationRequestId });
      // include a random error because we don't have anything else to tell them, we did in the past support this endpoint, but now it no longer servers any purpose
      this.error = 'MISSING_APPLICATION_ID';
      return;
    }

    const response = await new HttpClient().get('/authentication/configuration');
    this.domainConfiguration = response.data || {};
    this.loading = false;
  },

  methods: {
    /**
     * Handles Extension log in. Extension redirect here by default because they are using the OAuth flow, which says directly navigate to the /authorize url (which in this case is this top level route). Then we have to handle the authentication directly
     */
    async extensionAndLegacyNonRequestOauthFlow() {
      if (!this.applicationId) {
        this.error = 'MISSING_APPLICATION_ID';
        return;
      }

      const codeChallenge = this.$route.query.code_challenge;
      if (!codeChallenge) {
        logger.track({ title: 'Use ended up at the main page without a code challenge, which means they did not come from the login SDK. If we do not have a code challenge we cannot continue because the API will reject those requests.', pageData: this.$data });
        this.error = 'MISSING_CODE_CHALLENGE';
        return;
      }

      if (this.unexpectedParameterInUrl) {
        logger.track({ title: '[Action Required] Unexpected parameter found in the URL for dynamically generating oauth requests, we need to block this so that future requests can start using that parameter correctly and behavior will not accidentally change for this account.', pageData: this.$data, unexpectedParameterInUrl: this.unexpectedParameterInUrl });
        // this.error = 'UNEXPECTED_PARAMETER_IN_URL';
        // return;
      }

      // If the user isn't logged in
      // * Generate a new authentication request
      // * Save it with the necessary info
      // * redirect them to the customers login portal

      // if the user is logged in generate the token and redirect them back to where they came from with a valid authorization code to exchange for a real token

      try {
        const antiAbuseHash = await jwtManager.calculateAntiAbuseHash({ inviteId: this.inviteId, applicationId: this.applicationId });
        const authenticationRequest = await new HttpClient().post('/authentication', {
          antiAbuseHash,
          resource: this.resourceAudienceIdUri,
          source: 'HOSTED_LOGIN',
          redirectUrl: this.redirectUrl,
          codeChallengeMethod: 'S256',
          codeChallenge,
          inviteId: this.inviteId,
          state: this.$route.query.state,
          requestedScopes: this.$route.query.scope,
          responseLocation: this.$route.query.response_mode || 'query',
          flowType: 'code',
          applicationId: this.applicationId
        });

        // Don't navigate to the login page if we are already on the login page, instead just update the state make sure we include everything we could ever need here.
        if (window.location.origin === new URL(authenticationRequest.data.authenticationUrl).origin
          && window.location.pathname === new URL(authenticationRequest.data.authenticationUrl).pathname) {
          this.$store.commit('setAuthenticationRequest', authenticationRequest.data.authenticationRequestId);
          try {
            window.history.pushState(null, '', authenticationRequest.data.authenticationUrl);
          } catch (error) {
            logger.warn({ title: 'Failed to push state', error, authenticationRequest });
          }

          const newQuery = {};
          [...new URLSearchParams(new URL(authenticationRequest.data.authenticationUrl).search.slice(1)).entries()].map(([k, v]) => {
            newQuery[k] = v;
          });
          this.$router.replace({ query: newQuery });
          this.loading = false;
          return;
        }

        logger.log({ title: 'Redirecting the user after an authentication request was created from OAuth authorize endpoint.', authenticationRequest: authenticationRequest.data, location: window.location.href });
        window.location.assign(authenticationRequest.data.authenticationUrl);
      } catch (error) {
        if (error.data?.errorCode === 'InvalidResource') {
          this.error = 'INVALID_RESOURCE';
          return;
        }
        if (error.data?.errorCode === 'InvalidApplication') {
          this.error = 'INVALID_APPLICATION';
          return;
        }

        if (error.data?.errorCode === 'InvalidRedirectUrl') {
          this.error = 'INVALID_REDIRECT';
          return;
        }

        if (error.data?.errorCode === 'InvalidInvite') {
          this.error = 'INVALID_INVITE';
          return;
        }

        if (error.data?.errorCode === 'InvalidRequest') {
          this.error = error.data.title;
          return;
        }

        logger.track({ title: `Missing explicit handling for pushed authentication request creation handling: ${error.data?.errorCode || '<None>'}`, error });
        if (error.data?.title) {
          this.error = `The follow configuration error has occurred: ${error.data.title}`;
          return;
        }

        this.error = 'An unknown error has occurred, please try again';
      }
    },

    returnToApp() {
      this.loading = true;
      this.error = null;

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.replace(appLocation);
    }
  }
};
</script>

<style scoped>
</style>
