import Home from './views/home';
import Login from './views/login';
import Logout from './views/logout';
import Profile from './views/profile';
import Settings from './views/settings.vue';
import ExtensionTester from './views/extensionTester.vue';
import DevelopmentRedirect from './views/developmentRedirect.vue';

export default function routes() {
  return [
    {
      path: '*',
      redirect: '/'
    },
    // The redirect provider
    {
      path: '/login',
      props: true,
      name: 'Login',
      component: Login
    },

    {
      path: '/logout',
      props: true,
      name: 'Logout',
      component: Logout
    },

    {
      path: '/settings',
      props: true,
      name: 'Settings',
      component: Settings
    },

    {
      path: '/profile',
      props: true,
      name: 'Profile',
      component: Profile
    },

    {
      path: '/tester',
      props: true,
      name: 'ExtensionTester',
      component: ExtensionTester
    },

    {
      path: '/development-redirect',
      props: true,
      name: 'DevelopmentRedirect',
      component: DevelopmentRedirect
    },

    // There is already an authentication request, so fetch it and then handle showing the user the right thing or there isn't and let the user login, or this is the login screen for their platform
    // And let them decide how they want to log in
    {
      path: '/:authenticationRequestId?',
      props: true,
      name: 'Home',
      component: Home
    }
  ];
}
