<template>
  <transition name="slide-fade-enter" appear>
    <div class="">
      <div class="">
        <h3 class="d-flex align-items-center justify-content-center">
          <strong>Welcome to Authress Federation</strong>
        </h3>

        <br>
        <div class="d-flex justify-content-center"><span>There's nothing to see here yet! As soon as you start to log into new applications,
          your profile here will be populated with the applications secured with your Authress account.</span></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProfileScreen',

  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {}
};
</script>

<style scoped>

</style>
