<template>
  <div :class="{ 'pageLoader': pageLoader }">
    <font-awesome-icon class="spinner" icon="spinner" size="3x" spin />
  </div>
</template>

<script>
// https://github.com/FortAwesome/vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
library.add(faSpinner);

import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
library.add(faEnvelope);

export default {
  name: 'SpinnerWidget',

  components: { FontAwesomeIcon },
  props: {
    pageLoader: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
};
</script>

<style lang="scss" scoped>
  .pageLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9999;

    .spinner {
      color: var(--brand-color--fg, var(--light));
    }
  }

  .spinner {
    color: var(--brand-color--bg, var(--light));
  }
</style>
