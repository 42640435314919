import Vue from 'vue';
Vue.config.productionTip = false;

import 'error-object-polyfill';

import VueRouter from 'vue-router';

// import BuildInfo from './buildInfo';
import routes from './routes';
import store from './store';

Vue.use(VueRouter);

import Loader from './components/loader';
/* eslint-disable-next-line vue/multi-word-component-names,vue/component-definition-name-casing */
Vue.component('loader', Loader);

const App = () => import(/* webpackChunkName: "main" */ './app.vue');

import logger from './clients/logger';
import buildInfo from './buildInfo';
logger.log({ title: 'Version', version: buildInfo.version });

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* eslint-disable-next-line vue/multi-word-component-names,vue/component-definition-name-casing */
Vue.component('fa', FontAwesomeIcon);
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(require('@fortawesome/free-solid-svg-icons/faSpinner').definition);
library.add(require('@fortawesome/free-solid-svg-icons/faCheck').definition);
library.add(require('@fortawesome/free-solid-svg-icons/faLeftLong').definition);
library.add(require('@fortawesome/free-solid-svg-icons/faArrowLeft').definition);

function init() {
  store.commit('initialize');

  const router = new VueRouter({
    mode: 'history',
    routes: routes(),
    store,
    linkActiveClass: 'active'
  });

  new Vue({
    router,
    store,

    provide: {
    },

    created() {
      router.afterEach(to => {
        document.title = this.getDocumentTitle(to);
        window.scrollTo(0, 0);
      });
      document.title = this.getDocumentTitle(this.$route);
    },

    methods: {
      getDocumentTitle() {
        return 'Login';
      }
    },
    render: h => h(App)
  }).$mount('#app');
}

init();
