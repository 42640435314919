import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const plugins = [createPersistedState({ key: 'login', paths: ['settings', 'cache', 'isDev'] })];

// import createLogger from 'vuex/dist/logger';
// const isDevelopment = process.env.NODE_ENV && process.env.NODE_ENV === 'development';
// if (isDevelopment) { plugins.push(createLogger()); }

export default new Vuex.Store({
  plugins,
  state: {
    initialized: false,
    authenticationRequestId: null,
    cache: {
      lastSelectedConnection: null,

      errorTracking: {
        chunkLoadingError: null
      }
    }
  },
  mutations: {
    initialize(state) {
      state.initialized = true;
    },

    setAuthenticationRequest(state, authenticationRequestId) {
      state.authenticationRequestId = authenticationRequestId;
    },
    setLastSelectedConnection(state, lastSelectedConnection) {
      if (!state.cache) {
        state.cache = {};
      }
      state.cache.lastSelectedConnection = lastSelectedConnection;
    },

    trackChunkLoadingError(state, version) {
      if (!state.cache.errorTracking) {
        state.cache.errorTracking = {};
      }
      state.cache.errorTracking.chunkLoadingError = version;
    }
  },
  actions: {},
  getters: {
    host() {
      return window.location.hostname.match('authress.io') ? 'authress.io' : window.location.hostname.replace(/^[^.]+[.]/, '');
    }
  }
});
