<template>
  <div v-if="loading">
    <loader pageLoader />
  </div>
  <div v-else>
    <transition name="slide-fade-enter" appear>
      <div class="display-area p-5">
        <div class="">
          <h3 class="d-flex align-items-center justify-content-center">
            <strong>Login</strong>
          </h3>
        </div>
        <div class="d-panel-body d-flex justify-content-center">
          <div class="group">
            <b-form-group>
              <div>
                <span>Test Login with extension for platform:</span>
                <pre><code>{{ platformDomain }}</code></pre>
              </div>
              <div class="">
                <span>Using extension:</span>
                <pre><code>{{ extensionId }}</code></pre>
              </div>

              <div v-if="!tokenResponseFormatted">
                <activity-button :action="() => loginWithPlatform()" variant="outline-primary" class="mr-2">Test Login with Extension for Platform</activity-button>
              </div>
              <div v-else>
                <span>Token Response</span>
                <pre><code>{{ tokenResponseFormatted }}</code></pre>

                <span>User Identity</span>
                <pre><code>{{ userIdentityFormatted }}</code></pre>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import { ExtensionClient } from '@authress/login';

import ActivityButton from '../components/activityButton.vue';
import logger from '../clients/logger';

export default {
  name: 'ExtensionTester',

  components: { BFormGroup, ActivityButton },

  data() {
    return {
      host: this.$store.getters.host,
      loading: true,
      tokenResponse: null,
      userIdentityResponse: null
    };
  },

  computed: {
    extensionId() {
      return this.$route.query.extension;
    },
    platformDomain() {
      return this.$route.query.domain || window.location.host;
    },
    redirectUrl() {
      return `${window.location.origin}/tester?extension=${this.extensionId}&domain=${this.platformDomain}`;
    },
    tokenResponseFormatted() {
      return this.tokenResponse && JSON.stringify(this.tokenResponse, null, 2);
    },
    userIdentityFormatted() {
      return this.userIdentityResponse && JSON.stringify(this.userIdentityResponse, null, 2);
    },
    extensionClient() {
      return new ExtensionClient(this.platformDomain, this.extensionId);
    }
  },

  created() {
    this.extensionClient.getUserIdentity();
    setTimeout(async () => {
      try {
        this.userIdentityResponse = await this.extensionClient.getUserIdentity();
        if (this.userIdentityResponse) {
          this.tokenResponse = await this.extensionClient.login();
        }
      } catch (error) {
        logger.error({ title: 'Failed to load the result in the extension tester', error });
      }
      this.loading = false;
    }, 1000);
  },

  methods: {
    async loginWithPlatform() {
      await this.extensionClient.login(this.redirectUrl);
      this.userIdentityResponse = await this.extensionClient.getUserIdentity();
      if (this.userIdentityResponse) {
        this.tokenResponse = await this.extensionClient.login();
      }
    }
  }
};
</script>

<style scoped>
pre code {
  max-width: 100%;
  color: #e83e8c !important;
  white-space: break-spaces;
}
</style>
