<template>
  <div>
    <div>
      <loader pageLoader />
    </div>
  </div>
</template>

<script>
import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';

export default {
  name: 'DevelopmentRedirectScreen',

  components: { },

  data() {
    return {
      host: this.$store.getters.host,
      loading: true
    };
  },

  async created() {
    const newSearchParams = new URLSearchParams(window.location.search.slice(1));
    const combinedState = newSearchParams.get('state');
    if (!combinedState) {
      this.redirectToLogin();
      return;
    }
    let host;
    if (combinedState.startsWith('dev~')) {
      const combinedStateTokens = combinedState.split('~');
      host = combinedStateTokens[1];
      newSearchParams.set('state', combinedStateTokens.slice(2).join('~'));
    } else {
      host = combinedState.split('|')[0];
      newSearchParams.set('state', combinedState.split('|').slice(1).join('|'));
    }

    try {
      // eslint-disable-next-line no-new
      new URL(`https://${host}`);
    } catch (error) {
      this.redirectToLogin('invalidHost', `The host url set for this request is not a valid Authress hosted login url: ${error.message}`);
      return;
    }

    // This check prevents this endpoint from becoming an open redirector on the internet. Before redirecting anywhere it first validates the source of the request matches the host.
    // * A malicious attacker could still setup their own Authress account and attempt host malicious code as an app. But at that point they've identified themselves to us
    // * And we can directly take action against their account.
    try {
      await new HttpClient().get(`/authentication/${encodeURIComponent(newSearchParams.get('state'))}`);

      // If we can fetch the auth request, then everything is okay, we actually don't need to do further validation because the validation is handled on the service side.
      const successfulHostedLoginUrl = new URL(`https://${host}/login`);
      successfulHostedLoginUrl.search = newSearchParams;
      const newUrl = successfulHostedLoginUrl.toString();
      window.location.replace(newUrl);
      return;
    } catch (error) {
      if (error.data?.redirectUrl) {
        window.location.replace(error.data.redirectUrl);
        return;
      }

      logger.track({ title: `Failed to fetch authentication request for validation during development redirect for connection, sending the user back two pages - ${error.message || error.data?.title || ''}`, host, error, newSearchParams });
      // We go back two, because going back only one is going to put them back on the 3rd party provider login, which would just redirect them back here, we want to send them back to the original starting point.
      history.go(-1);

      // Browsers might barf when going back 2 when there is only one page in the history, so also try to go back 2 in two separate statements.
      history.go(-1);
      return;
    }
  },

  methods: {
    async redirectToLogin(errorCode, errorMessage) {
      await new Promise(resolve => setTimeout(resolve, 300));
      const defaultHostedLoginUrl = new URL('/login', window.location.origin);
      const newSearchParams = new URLSearchParams(window.location.search.slice(1));
      if (errorCode) {
        newSearchParams.set('authenticationRequestId', newSearchParams.get('state'));
        newSearchParams.set(errorCode, errorMessage);
        newSearchParams.delete('state');
      }
      defaultHostedLoginUrl.search = newSearchParams;
      const newUrl = defaultHostedLoginUrl.toString();
      window.location.replace(newUrl);
    }
  }
};
</script>

<style scoped>
</style>
